/* SeeMore.scss */

@import '../../utils/GlobalStyles.scss';

.see-more-button {
    background-color: transparent; // Same as card background
    color: $standard-font-colour; // Primary text color for contrast
    border: none; // Remove border
    border-radius: 14px;
    padding: 3px 8px;
    font-size: $standard-font-size;
    cursor: pointer;
    transition: transform 0.3s, opacity 0.3s;
    opacity: 0.6;
    text-wrap: nowrap;
    width: 110%;

    display: flex;
    align-items: center; // Align items in the center vertically
    justify-content: center; // Center content horizontally 

    &:hover {
        opacity: 1; // Full opacity on hover
        text-decoration: none;
    }
}

.button-icon {
    display: inline-block;
    margin-left: 4px; // Adjust spacing between text and icon
}

.MuiSvgIcon-root {
    font-size: $standard-font-size * 1.1 !important; // The !important is to override MUI default styles if necessary
}
